import React from "react";
import { Link } from "react-router-dom";

import MainIcon from "../images/profile/RIKALIM.png";

import "../styles/Header.css";

export default function Header() {
    return (
        <div className="Header">
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid nav-con-main">
                    <a href="/">
                        <img
                            className="navigator-icon"
                            src={MainIcon}
                            alt="Homepage"
                            width="120"
                            height="auto"
                        />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="navigator-text collapse navbar-collapse"
                        id="navbarNav"
                    >
                        <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                            <li className="nav-item">
                                <Link
                                    to="/"
                                    className="nav-link navigator active"
                                >
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="/about"
                                    className="nav-link navigator active"
                                >
                                    About
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link navigator active"
                                    to="/videogames"
                                >
                                    Video Games
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link navigator active"
                                    to="/leveldesign"
                                >
                                    Level Design
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link navigator active"
                                    to="/misc"
                                >
                                    Misc
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link navigator active"
                                    to="/contact"
                                >
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}
