import React from "react";
import ScrollToTop from "react-scroll-to-top";

//Starbreeze Studios - PD2, PD3
import PD3Game from "../images/videogames/videogame-payday3.png";
import PD2CrudeAkwaning from "../images/videogames/videogame-crudeawakeningheist.png";
import PD2HostileTakeover from "../images/videogames/videogame-hostiletakeover.png";
import PD2LostInTransit from "../images/videogames/videogame-lostintransit.png";
import PD2MidlandRanch from "../images/videogames/videogame-midlandranch.png";
import PD2MountainMaster from "../images/videogames/videogame-mountainmaster.png";

//Ubisoft Singapore - Assassin's Creed
import ACVahallaSoP from "../images/videogames/videogame-acvalhallasop.png";
import ACVahalla from "../images/videogames/videogame-acvalhalla.png";
import ACOdyssey from "../images/videogames/videogame-acodyssey.png";
import ACOrigins from "../images/videogames/videogame-acorigins.png";
import ACSyndicate from "../images/videogames/videogame-acsyndicate.png";

export default function VideoGames() {
    return (
        <div className="VideoGames">
            <ScrollToTop smooth width="20" height="20" />
            <div className="hero-sub">
                <h1>Released Video Game Titles</h1>
                <h2>Credits as a Game Developer</h2>
            </div>

            <div className="container text-center">
                <div className=" row row-cols-1 row-cols-md-3 g-4 justify-content-center">
                    <div className="col">
                        <div className="card">
                            <img
                                src={PD3Game}
                                className="img-fluid card-img-top aboutimg-thumbnail mx-auto"
                                alt="PAYDAY 3"
                            />
                            <div className="card-body">
                                <h5 className="card-title">PAYDAY 3</h5>
                                <p className="card-text">
                                    21 September, 2023
                                    <br />
                                    <em>Lead Level Designer</em>
                                </p>
                                <a
                                    href="https://store.steampowered.com/app/1272080/PAYDAY_3/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    GAME PAGE
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={PD2CrudeAkwaning}
                                className="img-fluid card-img-top aboutimg-thumbnail mx-auto"
                                alt="PAYDAY 2 Texas Heat - Crude Awakening"
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Crude Awakening Heist
                                </h5>
                                <p className="card-text">
                                    PAYDAY 2 - Texas Heat (2023)
                                    <br />
                                    <em>Lead Level Designer</em>
                                </p>
                                <a
                                    href="https://store.steampowered.com/app/2353512/PAYDAY_2_Crude_Awakening_Heist/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Game Page
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={PD2HostileTakeover}
                                className="img-fluid card-img-top aboutimg-thumbnail mx-auto"
                                alt="PAYDAY 2 Texas Heat - Hostile Takeover"
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Hostile Takeover Heist
                                </h5>
                                <p className="card-text">
                                    PAYDAY 2 - Texas Heat (2023)
                                    <br />
                                    <em>Lead Level Designer</em>
                                </p>
                                <a
                                    href="https://store.steampowered.com/app/2215010/PAYDAY_2_Hostile_Takeover_Heist/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Game Page
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={PD2LostInTransit}
                                className="img-fluid card-img-top aboutimg-thumbnail mx-auto"
                                alt="PAYDAY 2 Texas Heat - Lost in Transit"
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Lost in Transit Heist
                                </h5>
                                <p className="card-text">
                                    PAYDAY 2 - Texas Heat (2022)
                                    <br />
                                    <em>Lead Level Designer</em>
                                </p>
                                <a
                                    href="https://store.steampowered.com/app/2074240/PAYDAY_2_Lost_in_Transit_Heist/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Game Page
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={PD2MidlandRanch}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="PAYDAY 2 Texas Heat - Midland Ranch"
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Midland Ranch Heist
                                </h5>
                                <p className="card-text">
                                    PAYDAY 2 - Texas Heat (2022)
                                    <br />
                                    <em>Lead Level Designer</em>
                                </p>
                                <a
                                    href="https://store.steampowered.com/app/1945681/PAYDAY_2_Midland_Ranch_Heist/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Game Page
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={PD2MountainMaster}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="PAYDAY 2 City of Gold - Mountain Master Heist"
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Mountain Master Heist
                                </h5>
                                <p className="card-text">
                                    PAYDAY 2 - City of Gold (2022)
                                    <br />
                                    <em>Lead Level Designer</em>
                                </p>
                                <a
                                    href="https://store.steampowered.com/app/1906240/PAYDAY_2_Mountain_Master_Heist/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Game Page
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={ACVahallaSoP}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="Assassin's Creed Valhalla - Siege of Paris"
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    AC Valhalla: Siege of Paris
                                </h5>
                                <p className="card-text">
                                    Assassin's Creed (2021 - DLC)
                                    <br />
                                    <em>Lead Level Designer</em>
                                </p>
                                <a
                                    href="https://www.ubisoft.com/en-gb/game/assassins-creed/valhalla/post-launch"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Game Page
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={ACVahalla}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="Assassin's Creed Valhalla"
                            />
                            <div className="card-body">
                                <h5 className="card-title">AC Valhalla</h5>
                                <p className="card-text">
                                    Assassin's Creed (2020)
                                    <br />
                                    <em>Associate Lead Level Designer</em>
                                </p>
                                <a
                                    href="https://www.ubisoft.com/en-us/game/assassins-creed/valhalla"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Game Page
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={ACOdyssey}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="Assassin's Creed Odyssey"
                            />
                            <div className="card-body">
                                <h5 className="card-title">AC Odyssey</h5>
                                <p className="card-text">
                                    Assassin's Creed (2018)
                                    <br />
                                    <em>Associate Lead Level Designer</em>
                                </p>
                                <a
                                    href="https://www.ubisoft.com/en-us/game/assassins-creed/odyssey"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Game Page
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={ACOrigins}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="Assassin's Creed Origins"
                            />
                            <div className="card-body">
                                <h5 className="card-title">AC Origins</h5>
                                <p className="card-text">
                                    Assassin's Creed (2017)
                                    <br />
                                    <em>Level Designer</em>
                                </p>
                                <a
                                    href="https://www.ubisoft.com/en-us/game/assassins-creed/origins"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Game Page
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={ACSyndicate}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="Assassin's Creed Syndicate"
                            />
                            <div className="card-body">
                                <h5 className="card-title">AC Syndicate</h5>
                                <p className="card-text">
                                    Assassin's Creed (2015)
                                    <br />
                                    <em> Junior Level Designer</em>
                                </p>
                                <a
                                    href="https://www.ubisoft.com/sv-se/game/assassins-creed/syndicate"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Game Page
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
