import React from "react";
import ScrollToTop from "react-scroll-to-top";

//SheCodes
import SheCodesReactAddOns from "../images/shecodes/misc-shecodes-react-dictionary.png";
import SheCodesReact from "../images/shecodes/misc-shecodes-react-weather.png";
import SheCodesResponsive from "../images/shecodes/misc-shecodes-responsive-portfolio.png";
import SheCodesPlus from "../images/shecodes/misc-shecodes-plus-weather.png";
import SheCodesBasics from "../images/shecodes/misc-shecodes-basics-ragdoll.png";

import "../styles/Misc.css";

export default function Misc() {
    return (
        <div className="Misc">
            <ScrollToTop smooth width="20" height="20" />
            <div className="hero-sub">
                <h1>MISCELLANEOUS</h1>
                <h2>PERSONAL DEVELOPMENT AND LEARNING</h2>
            </div>
            <div className="container text-center">
                <div className=" row row-cols-1 row-cols-md-3 g-4 justify-content-center">
                    <div className="col">
                        <div className="card">
                            {" "}
                            <img
                                src={SheCodesReactAddOns}
                                className="card-img-top img-fluid mx-auto"
                                alt="SheCodes React Add-ons - Dictionary Project"
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Dictionary Search Engine
                                </h5>
                                <p className="card-text">
                                    SheCodes React Add-ons (2022)
                                </p>
                                <a
                                    href="https://www.shecodes.io/workshops/shecodes-react-add-on-a397009a-c590-4b35-ac0c-0129276fa36c/projects/1238801"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Project
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            {" "}
                            <img
                                src={SheCodesReact}
                                className="card-img-top img-fluid mx-auto"
                                alt="SheCodes React - Weather Project"
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Weather Search Engine
                                </h5>
                                <p className="card-text">
                                    SheCodes React (2022)
                                </p>
                                <a
                                    href="https://www.shecodes.io/workshops/shecodes-react-add-on-a397009a-c590-4b35-ac0c-0129276fa36c/projects/1238801"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Project
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            {" "}
                            <img
                                src={SheCodesResponsive}
                                className="card-img-top img-fluid mx-auto"
                                alt="SheCodes Responsive - Portfolio Website"
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Portfolio Website
                                </h5>
                                <p className="card-text">
                                    SheCodes Responsive (2022)
                                </p>
                                <a
                                    href="https://www.shecodes.io/workshops/shecodes-responsive-661ab3d4-56be-4654-ae58-f2c47d50eb8b/projects/1164049"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Project
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" row row-cols-1 row-cols-md-3 g-4 justify-content-center">
                    <div className="col">
                        <div className="card">
                            {" "}
                            <img
                                src={SheCodesPlus}
                                className="card-img-top img-fluid mx-auto"
                                alt="SheCodes Plus - Weather Project"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Weather Project</h5>
                                <p className="card-text">
                                    SheCodes Plus (2022)
                                </p>
                                <a
                                    href="https://www.shecodes.io/workshops/shecodes-plus-5593e186-905a-4268-8306-d45ff13457ed/projects/1121231"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Project
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            {" "}
                            <img
                                src={SheCodesBasics}
                                className="card-img-top img-fluid mx-auto"
                                alt="SheCodes Basics - Ragdoll"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Ragdoll Cats</h5>
                                <p className="card-text">
                                    SheCodes Basics (2022)
                                </p>
                                <a
                                    href="https://www.shecodes.io/workshops/shecodes-basics-3dc02b57-7a59-44fd-ac6b-1101ed309bcf/projects/1065719"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Project
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
