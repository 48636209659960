import React from "react";
import ScrollToTop from "react-scroll-to-top";

//Panels & Talks
import TechStory from "../images/leveldesign/leveldesign-mytechstory.png";
import PanelAAA from "../images/leveldesign/leveldesign-workinginAAA.png";
import LDMentoringLevelUp from "../images/leveldesign/leveldesign-ldmentoringlvlupkl.png";
import GamDevHappyHours from "../images/leveldesign/leveldesign-ldmentoringgamedev.png";

//Articles
import ArticleAUbisoftSGVinland from "../images/leveldesign/leveldesign-acvinlandsg.png";
import ArticleKotakuVinland from "../images/leveldesign/leveldesign-acvinlandkotaku.png";

//Videos
import ACOdysseyReels from "../images/leveldesign/leveldesign-acodysseyreel.png";
import ACOriginHawara from "../images/leveldesign/leveldesign-acoriginshawara.png";
import ACOriginsLimestoneQuarry from "../images/leveldesign/leveldesign-acoriginslimestonequarry.png";

import { LinkedInEmbed } from "react-social-media-embed";

export default function LevelDesign() {
    return (
        <div className="LevelDesign">
            <ScrollToTop smooth width="20" height="20" />
            <div className="hero-sub">
                <h1>World and Level Design</h1>
                <h2>Project Reels, Articles and Panel Discussions</h2>
            </div>

            <div className="container text-center">
                <div className=" row row-cols-1 row-cols-md-3 g-4 justify-content-center">
                    <div className="col">
                        <div className="card">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <LinkedInEmbed
                                    url="https://www.linkedin.com/embed/feed/update/urn:li:share:7131272840472907776"
                                    postUrl="https://www.linkedin.com/posts/jubbileus_level-design-resources-activity-7131272841441800192-9xLj?utm_source=share&utm_medium=member_desktop"
                                    width={350}
                                    height={430}
                                />
                            </div>
                            <a
                                href="https://app.milanote.com/1R3WQ51Gh0VHbT?p=4pN11kPoqDo"
                                target="_blank"
                                rel="noreferrer"
                                className="navigate-btn btn btn-light"
                            >
                                LD Resources
                            </a>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={TechStory}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="My Tech Story"
                            />
                            <div className="card-body">
                                <h5 className="card-title">My Tech Story</h5>
                                <p className="card-text">
                                    Singapore Computer Society (2023)
                                </p>
                                <a
                                    href="https://www.linkedin.com/posts/jubbileus_my-tech-story-rika-lim-activity-7027131720864780288-j51w/p"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Video
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={PanelAAA}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="Global Game Changers - Working in AAA"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Working in AAA</h5>
                                <p className="card-text">
                                    Global Game Changers (2022)
                                </p>
                                <a
                                    href="https://www.youtube.com/watch?v=M3d1csh2PFo"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Video
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={ArticleAUbisoftSGVinland}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="Made in Singapore – The Secrets of Assassin’s Creed Valhalla"
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    AC Valhalla - Vinland
                                </h5>
                                <p className="card-text">
                                    Ubisoft Singapore (2021)
                                </p>
                                <a
                                    href="https://www.ubisoftsingapore.com/post/made-in-singapore-the-secrets-of-assassin-s-creed-valhalla"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Article
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={ArticleKotakuVinland}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="The Making Of Assassin's Creed Valhalla's Best Bonus Region"
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    AC Valhalla - Vinland
                                </h5>
                                <p className="card-text">Kotaku (2021)</p>
                                <a
                                    href="https://kotaku.com/the-making-of-assassins-creed-valhallas-best-bonus-regi-1846161561"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Article
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={ACOdysseyReels}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="Rika Lim - Assassin’s Creed Odyssey World Locations"
                            />
                            <div className="card-body">
                                <h5 className="card-title">World Locations</h5>
                                <p className="card-text">
                                    Assassin's Creed Odyssey (2018)
                                </p>
                                <a
                                    href="https://youtu.be/wOElWRvqLPg"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Video
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={ACOriginHawara}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="Rika Lim - Assassin’s Creed Origins - Hawara Labyrinth"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Hawara Labyrinth</h5>
                                <p className="card-text">
                                    Assassin's Creed Origins (2017)
                                </p>
                                <a
                                    href="https://youtu.be/xfze8SUDGrk"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Video
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={ACOriginsLimestoneQuarry}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="Rika Lim - Assassin’s Creed Origins - Limestone Quarry"
                            />
                            <div className="card-body">
                                <h5 className="card-title">Limestone Quarry</h5>
                                <p className="card-text">
                                    Assassin's Creed Origins (2017)
                                </p>
                                <a
                                    href="https://youtu.be/-_pqMxZoBWU"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Video
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={LDMentoringLevelUp}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="Rika Lim Tutors Aspiring Game Devs About Level Design"
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Rika Lim Tutors Aspiring Game Devs About
                                    Level Design
                                </h5>
                                <p className="card-text">
                                    GamerBraves, Level Up KL 2020
                                </p>
                                <a
                                    href="https://www.gamerbraves.com/level-up-kl-2020-rika-lim-tutors-aspiring-game-devs-about-level-design/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Article
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img
                                src={GamDevHappyHours}
                                className="card-img-top img-fluid aboutimg-thumbnail mx-auto"
                                alt="Assassin’s Creed - Building a credible & engaging world"
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Assassin’s Creed: <br />
                                    Building a credible & engaging world
                                </h5>
                                <p className="card-text">
                                    Gamedev Happy Hours Oct 2020
                                </p>
                                <a
                                    href="https://youtu.be/CSHN5ULaIB4"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="navigate-btn btn btn-light"
                                >
                                    Video
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
