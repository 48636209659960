import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faReact,
    faLinkedin,
    faInstagram,
    faBootstrap,
} from "@fortawesome/free-brands-svg-icons";

import "../styles/Footer.css";

export default function Footer() {
    return (
        <div className="Footer">
            <footer className="bottom_nav">
                <div className="container text-center">
                    <div className="row">
                        <div className="col"></div>
                        <div className="col-auto text-center footer-icon-col">
                            <a
                                href="https://reactjs.org/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faReact}
                                    className="footer-icon"
                                />
                            </a>
                            <a
                                className="icons_main_page"
                                href="https://www.instagram.com/jubbileus/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faInstagram}
                                    className="footer-icon"
                                />
                            </a>
                            <a
                                className="icons_main_page"
                                href="https://www.linkedin.com/in/jubbileus/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faLinkedin}
                                    className="footer-icon"
                                />
                            </a>
                            <a
                                href="https://getbootstrap.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faBootstrap}
                                    className="footer-icon"
                                />
                            </a>
                        </div>
                        <div className="col"> </div>
                    </div>
                    <div className="text-center">
                        <span className="default-text">Coded by </span>
                        <a
                            className="text-link"
                            href="https://www.shecodes.io/graduates/49436-rika-lim-ming-xian"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Rika Lim
                        </a>
                        .
                        <br />
                        <span className="default-text">
                            © 2022 All rights reserved.
                        </span>
                    </div>
                </div>
            </footer>
        </div>
    );
}
