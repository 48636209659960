import "bootstrap/dist/css/bootstrap.css";

import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Main";
import About from "./pages/About";
import VideoGames from "./pages/VideoGames";
import LevelDesign from "./pages/LevelDesign";
import Misc from "./pages/Misc";
import Contact from "./pages/Contact";

import "./App.css";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="videogames" element={<VideoGames />} />
                    <Route path="leveldesign" element={<LevelDesign />} />
                    <Route path="misc" element={<Misc />} />
                    <Route path="contact" element={<Contact />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

ReactDOM.render(<App />, document.getElementById("root"));
