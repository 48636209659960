import React from "react";
import ScrollToTop from "react-scroll-to-top";

import digitalProfile from "../images/profile/avatar.JPG";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faRainbow } from "@fortawesome/free-solid-svg-icons";

import "../styles/Contact.css";

export default function Contact() {
    return (
        <div className="Contact">
            <ScrollToTop smooth width="20" height="20" />
            <div className="hero-sub">
                <h1>Contact Me</h1>
            </div>
            <div className="container contact-page text-center ">
                <div className="row justify-content-center row-cols-1 row-cols-md-3 g-3">
                    <div className="col order-1 order-md-2 justify-content-center">
                        <img
                            className="img-fluid rounded shadow"
                            src={digitalProfile}
                            alt="Rika Lim"
                            width="400px"
                        />
                    </div>

                    <div className="col pt-4 order-2 order-md-1 justify-content-center">
                        {" "}
                        <h3>
                            EMAIL{" "}
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className="in-page-icon"
                            />
                        </h3>
                        <p>
                            <a
                                className="text-link"
                                href="mailto:rika.limmx@outlook.com"
                            >
                                rika.limmx@outlook.com
                            </a>
                        </p>
                        <br />
                        <h3>
                            LINKEDIN{" "}
                            <FontAwesomeIcon
                                icon={faLinkedin}
                                classNameName="in-page-icon"
                            />
                        </h3>
                        <p>
                            <a
                                className="text-link"
                                href="https://www.linkedin.com/in/jubbileus/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Rika Lim
                            </a>
                        </p>
                        <br />
                        <h3>
                            BLUESKY{" "}
                            <FontAwesomeIcon
                                icon={faRainbow}
                                classNameName="in-page-icon"
                            />
                        </h3>
                        <p>
                            <a
                                className="text-link"
                                href="https://bsky.app/profile/jubbileus.bsky.social"
                                target="_blank"
                                rel="noreferrer"
                            >
                                jubbileus.bsky.social
                            </a>
                        </p>
                        <br />
                    </div>
                </div>

                <br />

                <br />
            </div>
        </div>
    );
}
